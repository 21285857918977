<!-- //
内容-视频发布/编辑页面
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="layout-vertical">
    <a-card style="width: 97.6%;margin: 10px auto;">
    <div class="layout-result">
      <div class="F18 F-BOLD" style="font-weight: bold;margin-bottom: 10px;">动态内容</div>
      <a-form-model
        ref="refForm"
        :model="form"
        layout="vertical"
        class="layout-form"
      >
        <a-form-model-item prop="fileName">
          <span slot="label" class="form-label">上传视频</span>
          <div class="upload-file" style="display:flex">
            <a-input v-model="form.fileName" class="upload-name" read-only />
            <a-upload
              name="videoUrl"
              accept="video/*"
              :multiple="false"
              :show-upload-list="false"
              :fileList="fileList"
            >
              <a-button size="large" disabled>重新上传视频</a-button>
            </a-upload>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <span slot="label" class="form-label">视频预览</span>
          <video
            ref="refVideo"
            width="320"
            height="240"
            controls
            autoplay
            muted
            crossOrigin="anonymous"
            :src="form.videoUrl"
          />
        </a-form-model-item>
        <a-form-model-item v-if="false">
          <span slot="label" class="form-label">短视频预览</span>
          <div class="preview-img" style="background-color: #d9d9d9">
            <img :src="gifUrl" style="height: 100%; width: 100%" />
          </div>
        </a-form-model-item>
        <a-form-model-item prop="cover">
          <span slot="label" class="form-label">封面</span>
          <EmptyImg class="preview-img" :previewImage="form.cover">
            <p slot="text">视频封面</p>
          </EmptyImg>
        </a-form-model-item>
        <a-form-model-item prop="title">
          <span slot="label" class="form-label">标题</span>
          <a-input
            v-model="form.title"
            class="form-value"
            placeholder="请输入标题，简要突出内容重点"
            :maxLength="titleMaxLength"
            size="large"
            disabled
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="content">
          <span slot="label" class="form-label">描述</span>
          <a-textarea
            v-model="form.content"
            class="form-value"
            style="height: 250px"
            disabled
          >
          </a-textarea>
        </a-form-model-item>
        <a-form-model-item prop="tag">
          <span slot="label" class="form-label">
            标签
          </span>
          {{ getstr(form.tags) }}
        </a-form-model-item>
      </a-form-model>
    </div>
    </a-card>
  </div>
</template>

<script>
import EmptyImg from "@/components/Empty";
import testVideo from "@/assets/test.mp4";

/**
 * 获取文件字节码
 *
 * @param file
 * @returns {Promise<unknown>}
 */
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "VideoEdit",
  components: { EmptyImg },
  props: {
    detaildata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        id: null,
        fileName: '', // 视频文件名称
        videoUrl: '', // 视频地址
        cover: '', // 封面
        shortVideoUrl: '', // 短视频标题
        title: '', // 视频标题
        content: '', // 视频内容
        tags: [], // 视频的兴趣标签
        productIds: [], // 视频的关联商品
      },
      previewVisible: false, // 切换显示empty
      previewImage: "", // 预览图片内容
      // 上传图片列表
      fileList: [],
      titleMaxLength: 30, // title最大len
      contentMaxLength: 100, // 描述最大len
      canvasList: [],
      imgList: [],
      gifUrl: null,
      testVideo: testVideo
    };
  },
  computed: {
  },
  mounted() {
  },
  watch: {
    detaildata: {
      immediate: true,
      handler(){
        this.form.fileName = this.detaildata.videoUri || ''; // 视频文件名称
        this.form.videoUrl = this.$const.OssImagePrefix + '/' + this.detaildata.videoUri; // 视频地址
        this.form.cover = this.$const.OssImagePrefix + '/' + this.detaildata.coverUri; // 封面
        this.form.shortVideoUrl = this.detaildata.originalName || ''; // 短视频标题
        this.form.title = this.detaildata.title || ''; // 视频标题
        this.form.content = this.detaildata.content || ''; // 视频内容
        this.form.tags = this.detaildata.trendTab || []; // 视频的兴趣标签
        let fileList = [];
        let oss = this.$const.OssImagePrefix;
        let pics = this.detaildata.trendPics || []
        for (var i = 0; i < pics.length; i++) {
          fileList.push({
            id: i + '',
            name: pics[i].split('/')[2],
            status: 'done',
            url: oss + '/' + pics[i]
          })
        }
        this.imgList = fileList;
        this.gifUrl = this.$const.OssImagePrefix + '/' + this.detaildata.uri;
      }
    }
  },
  methods: {
    /**
     * 点击预览
     * @param file
     * @returns {Promise<void>}
     */
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getstr(ary) {
      if (ary && ary.length > 0) {
        return ary.join('、');
      }
      return '暂无';
    }
  },
};
</script>
<style lang="less" scoped>
.layout-form {
  padding: 24px;
  display: flex;
  flex-direction: column;

  .form-label {
    font-size: 16px;
    font-weight: 600;

    .tip {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .form-value {
    min-width: 375px;
    max-width: 700px;
  }

  .preview-img {
    width: 235px;
    height: 294px;
  }

  .upload-file {
    margin-top: 8px;

    .upload-name {
      max-width: 700px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
</style>
