<!-- //
动态编辑
@auth sf
@date 2022-09-28
//-->
<template>
  <div class="layout-vertical">
    <a-card style="width: 97.6%;margin: 10px auto;">
      <div class="layout-result">
        <div class="F18 F-BOLD" style="font-weight: bold;margin-bottom: 10px;">动态内容</div>
        <a-form-model
          ref="refForm"
          :model="form"
          layout="vertical"
          class="layout-form"
        >
          <div class="form-left">
            <span slot="label" class="form-label">图片</span>
            <EmptyImg class="img-preview" :previewImage="previewImage">
              <p slot="text">可上传1-9张图片,第一张为动态封面</p>
              <p slot="hint">支持格式：.jpeg .jpg .png .gif .bmp</p>
            </EmptyImg>
            <div class="img-upload">
              <div v-for="(item,index) in fileList" :key="index" >
                <viewer :images="[`${item.url}`]">
                  <img style="height:90px;width:90px;margin-right: 10px;" :src="item.url" />
                </viewer>
              </div>
            </div>
          </div>
          <div class="form-right">
            <a-form-model-item prop="title">
              <span slot="label" class="form-label">标题</span>
              <a-input
                v-model="form.title"
                class="form-value"
                placeholder="请输入标题，简要突出内容重点"
                size="large"
                disabled
              >
              </a-input>
            </a-form-model-item>

            <a-form-model-item prop="content">
              <span slot="label" class="form-label">描述</span>
              <a-textarea
                v-model="form.content"
                class="form-value"
                style="height: 250px"
                disabled
              >
              </a-textarea>
            </a-form-model-item>

            <a-form-model-item prop="tag">
              <span slot="label" class="form-label">
                标签
              </span>
              {{ getstr(form.tags) }}
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-card>
  </div>
</template>

<script>
import EmptyImg from "@/components/Empty";

/**
 * 获取文件字节码
 *
 * @param file
 * @returns {Promise<unknown>}
 */
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "TrendEdit",
  components: { EmptyImg },
  props: {
    detaildata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        id: null,
        title: '', // 动态的标题
        content: '', // 动态内容
        pics: [], // 动态的图片
        cover: '', // 封面
        tags: [], // 动态的兴趣标签
        productIds: [] // 动态的关联商品
      },
      previewVisible: false, // 切换显示empty
      previewImage: "", // 预览图片内容
      contentMaxLength: 250, // 描述最大len
      // 上传图片列表
      fileList: []
    };
  },
  computed: {
  },
  watch: {
    detaildata: {
      immediate: true,
      handler(){
        console.log(this.detaildata)
        this.form.title = this.detaildata.title || ''; // 动态的标题
        this.form.content = this.detaildata.content || ''; // 动态内容
        this.form.pics = this.detaildata.trendPics || []; // 动态的图片
        this.form.cover = this.$const.OssImagePrefix + '/' + this.detaildata.coverUri; // 封面
        this.form.tags = this.detaildata.trendTab || []; // 动态的兴趣标签
        let fileList = [];
        let oss = this.$const.OssImagePrefix;
        for (var i = 0; i < this.form.pics.length; i++) {
          fileList.push({
            id: i + '',
            name: this.form.pics[i].split('/')[2],
            status: 'done',
            url: oss + '/' + this.form.pics[i]
          })
        }
        this.fileList = fileList;
        this.previewImage = this.form.cover;
        console.log(this.fileList)
      }
    }
  },
  mounted() {
    this.form.id = this.$route.query.id;
  },
  methods: {
    /**
     * 点击预览
     * @param file
     * @returns {Promise<void>}
     */
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getstr(ary) {
      if (ary && ary.length > 0) {
        return ary.join('、');
      }
      return '暂无';
    }
  }
};
</script>
<style>
.img-uploader .ant-upload-list-picture-card-container,
.img-uploader .ant-upload-list-picture-card .ant-upload-list-item,
.img-uploader > .ant-upload {
  width: 84px;
  height: 84px;
}

.img-uploader .ant-upload-list-picture-card-container {
  margin: 6px 4px 6px 4px;
}

.img-uploader .ant-upload {
  margin: 6px 4px 6px 4px;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}
</style>
<style lang="less" scoped>
.form-label {
  font-size: 16px;
  font-weight: 600;

  .tip {
    font-size: 12px;
    font-weight: 300;
  }
}

.form-value {
  min-width: 375px;
  max-width: 700px;
}

.layout-form {
  padding: 24px;
  display: flex;
  .form-left {
    width: 375px;
  }

  .img-preview {
    width: 375px;
    height: 465px;

    .upload-hint,
    .upload-text {
      font-size: 14px;
      font-weight: 400;
    }

    .upload-text {
      color: #000000a5;
    }

    .upload-hint {
      color: #00000072;
    }
  }

  .img-upload {
    margin-top: 8px;
    width: 400px;
    overflow: hidden;
  }
  .img-upload div{
    width: 100px;
    height: 100px;
    float: left;
  }

  .form-right {
    flex: 1;
    margin-left: 48px;
  }
}
</style>
