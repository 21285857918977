<!-- //
图片显示组件，空和有图片状态
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="upload-container">
    <span class="upload-img" v-if="previewImage">
      <img class="img" :src="previewImage" />
    </span>

    <span class="upload-empty" v-else>
      <p class="upload-icon">
        <i aria-label="icon: inbox" class="icon">
          <svg
            viewBox="0 0 1024 1024"
            data-icon="inbox"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
            focusable="false"
            class=""
          >
            <path
              d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"
            ></path>
          </svg>
        </i>
      </p>
      <p class="upload-text">
        <slot name="text">{{ text }}</slot>
      </p>
      <p class="upload-hint">
        <slot name="hint">{{ hint }}</slot>
      </p>
    </span>
  </div>
</template>

<script>
export default {
  name: "Empty",
  components: {},
  props: {
    previewImage: String,
    text: String,
    hint: String
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.upload-container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  transition: border-color 0.3s;
  border-radius: 4px;
  display: table;
  /*cursor: pointer;*/
  .upload-img {
    display: table-cell;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #404040;
    vertical-align: middle;
    .img {
      width: 100%;
    }
  }

  .upload-empty {
    display: table-cell;
    vertical-align: middle;

    .upload-icon {
      margin-bottom: 20px;

      p {
        margin: 0;
      }

      .icon {
        color: #000000a5;
        font-size: 90px;
      }
    }

    .upload-text {
      color: #000000a5;
      font-size: 14px;
      font-weight: 400;
    }

    .upload-hint {
      color: #00000072;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
